module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@5.25.0_babel-plugin-styled-components@2.1.4_@babel+core@7.23._kxxb3ulwar5nnly5iuzkkumcj4/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"minify":true,"transpileTemplateLiterals":false,"fileName":true,"namespace":"","topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@4.25.0_gatsby@4.25.8_@swc+core@1.3.99_@swc+helpers@0.5.3__babel-esl_5sx6rgdemxfyas46xuknxwxaxu/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[null],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-posthog@1.0.1_gatsby@4.25.8_@swc+core@1.3.99_@swc+helpers@0.5.3__babel-eslint@1_cux6xjzocv5mqvfcpfpa6jym6e/node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_m0I2iQBuO0scoVHYinWwCk9VVhxSJcVPRV4BRt3SJKx","apiHost":"https://us.i.posthog.com","head":true,"initOptions":{"autocapture":false}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.25.8_@swc+core@1.3.99_@swc+helpers@0.5.3__babel-eslint@10.1.0_eslint@8.54.0__encodin_do2pryc3a5xyu4bns67r7t6ni4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
